import React, { useState } from 'react';
import './assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Container } from 'react-bootstrap';
import Asistencia from './components/asistencia';
import Main from './components/main';

function App() {
  const [showAsistencia, setShowAsistencia] = useState(false);

  const handlePostSuccess = () => {
    setShowAsistencia(true);
  };

  return (
    <div className="App">
      <header><h1>Registro de asistencia</h1></header>
      <Container>
        <Row>
          <Col xs={6}>
            {showAsistencia && <Asistencia />}
          </Col>
          <Col xs={6}>
            <Main onPostSuccess={handlePostSuccess} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
