import React, { useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import showAlert from './sweet'; // Ajusta la ruta según la ubicación de tu archivo

function Main({ onPostSuccess }) {
  const [clave, setClave] = useState('');
  const [guardia, setGuardia] = useState('1');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      clave: parseInt(clave, 10),
      guardia: parseInt(guardia, 10),
    };

    try {
      const response = await fetch('https://sospuebla.net/api/registro.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      console.log('Success:', result);

      showAlert({
        Message: result.Message || result.message || 'Éxito',
        Details: result.Details || result.details || 'La operación se completó correctamente.',
      });

      // Llama a la función para mostrar `Asistencia`
      onPostSuccess();
    } catch (error) {
      console.error('Error:', error);

      showAlert({
        Message: 'Error',
        Details: 'Ocurrió un problema al enviar los datos.',
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Col} md="4" controlId="validationCustom01">
        <Form.Label>Clave Operativa</Form.Label>
        <Form.Control
          required
          type="number"
          placeholder="Clave Operativa"
          value={clave}
          onChange={(e) => setClave(e.target.value)}
        />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="4" controlId="validationCustom02">
        <Form.Label>Guardia</Form.Label>
        <Form.Control
          as="select"
          required
          value={guardia}
          onChange={(e) => setGuardia(e.target.value)}
        >
          <option value="1">Viernes Noche</option>
          <option value="2">Sábado Día</option>
          <option value="3">Sábado Noche</option>
          <option value="4">Domingo Día</option>
        </Form.Control>
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group>
      <Button type="submit">Submit</Button>
    </Form>
  );
}

export default Main;
