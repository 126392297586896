import Swal from 'sweetalert2';

function showAlert({ Message, Details }) {
    Swal.fire({
      title: Message,
      text: Details,
      icon: 'info', // Puedes ajustar el icono según el contexto (success, error, info, etc.)
      timer: 3000, // Tiempo en milisegundos antes de que la alerta se cierre automáticamente
      timerProgressBar: true, // Opcional: muestra una barra de progreso del temporizador
      showConfirmButton: false, // Opcional: oculta el botón de confirmación
      willClose: () => {
        Swal.getTimerLeft(); // Opcional: permite realizar alguna acción antes de cerrar
      }
    });
  }

export default showAlert;
