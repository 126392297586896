import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

function Asistencia() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Función para obtener datos del endpoint
    const fetchData = async () => {
      try {
        const response = await fetch('https://sospuebla.net/api/registro.php?guardia=2');
        const result = await response.json();
        setData(result); // Asume que el resultado es un array de objetos
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Clave Operativa</th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (
          data.map((item, index) => (
            <tr key={index}>
              <td>{item.nombre}</td> {/* Ajusta estas claves según la estructura de tus datos */}
              <td>{item.apellido}</td>
              <td>{item.claveOperativa}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="3">No data available</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default Asistencia;
